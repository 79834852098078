export const checkDomain = () => {
  if (document.location.hostname === 'gooberrecords.com') {
    document.location.href = 'https://elisetrouw.com'
  }
  if (document.location.hostname === 'www.gooberrecords.com') {
    document.location.href = 'https://elisetrouw.com'
  }
  if (window.location.hostname === 'www.elisetrouw.com') {
    window.location.href = 'https://elisetrouw.com'
  }
  if (window.location.pathname === '/shop') {
    window.location.href = 'https://shop.elisetrouw.com'
  }
  if (window.location.pathname === '/store') {
    window.location.href = 'https://shop.elisetrouw.com'
  }
}
