import { Toolbar, ToolbarProps } from '@mui/material'
import React from 'react'

import { Socials } from './Socials'
import { SocialsMenu } from './SocialsMenu'

export const SocialsToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SocialsMenu sx={{ display: { lg: 'none', xs: 'flex' } }} />
      <Socials sx={{ display: { lg: 'flex', xs: 'none' } }} />
    </Toolbar>
  )
}
