import { Box, Container, Link, Typography } from '@mui/material'
import React from 'react'
import ReactPlayer from 'react-player/youtube'

import { CustomAppBar } from '../CustomAppBar'
import { CustomBasePage } from '../CustomBasePage'

export const Remix: React.FC = () => {
  return (
    <CustomBasePage appBar={<CustomAppBar />} paddingY={8}>
      <Container maxWidth="md">
        <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Typography align="center" variant="h1">
            #EliseCollab
          </Typography>
          <Typography align="center" variant="h2">
            Part II
          </Typography>
          <Typography align="center" variant="h3">
            {"'How to Get What You Want'"} Remix
          </Typography>
          <Box display="flex" style={{ paddingTop: '56.25%', position: 'relative' }} width="100%">
            <ReactPlayer height="100%" style={{ left: 0, position: 'absolute', top: 0 }} url="https://youtu.be/h8cbn8_6dQA" width="100%" />
          </Box>
          <Box paddingTop={4}>
            <Typography align="center" variant="h4">
              How to join:
            </Typography>
          </Box>
          <Typography align="left" variant="h4">
            <ol>
              <li>
                <Link href="https://www.dropbox.com/sh/f4e19qu6glzwogk/AAAXc670PCdOur6rSrIA7M4Sa?dl=0">Download the stems here</Link>
              </li>
              <li>Make something!</li>
              <li>Upload your remix to Soundcloud</li>
              <li>
                Get your track on the &apso;HTGWYW Remixes&apso; playlist by sending an email with a link to your track on Soundcloud to
                remix@elisetrouw.com
              </li>
              <li>(optional) Share your track on Instagram with the hashtag #htgwywremix and #elisecollab</li>
            </ol>
          </Typography>
          <Box alignItems="flex-start" display="flex" flexDirection="column" justifyContent="flex-start">
            <Typography align="left" variant="h5">
              About:
            </Typography>
            <Typography align="left" variant="h5">
              During the first few months of quarantine, I began an online collaboration that was later titled #EliseCollab. It started with a video I
              posted on Instagram of a drum groove. Someone commented that they would like the audio file to mess around with, so I posted the beat to
              Dropbox for anyone to download.
            </Typography>
            <Typography align="left" variant="h5">
              This began a series of beats starting at 90 bpm, going up to 140 bpm in increments of 10. Each video’s audio was available for download,
              and this led to a flood of videos being posted to the hashtag #EliseCollab!
            </Typography>
            <Typography align="left" variant="h5">
              At this point, I realized my latest release, “How to Get What You Want” is nearly at 150 bpm, so I decided to release it’s stems for you
              to download and have fun with. Scroll to the top of this page and watch the video for more information on part 2 of the collab.
            </Typography>
            <Box paddingY={3}>
              <Typography align="left" variant="h5">
                <Link href="https://www.instagram.com/explore/tags/elisecollab/" target="_blank">
                  Watch everyone’s collaborations and my original posts
                </Link>
              </Typography>
            </Box>
            <Typography align="left" variant="h5">
              <Link href="https://www.dropbox.com/sh/6xqg4n0s25nzww5/AACBAdNaMEbUOPer-puOd5Sea?dl=0" target="_blank">
                Download the files from the #EliseCollab Part I
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </CustomBasePage>
  )
}
