import { ButtonEx, FlexBoxProps, FlexRow } from '@xylabs/sdk-react'
import React from 'react'
import { AiOutlineApple, AiOutlineShoppingCart } from 'react-icons/ai'
import { FaSpotify } from 'react-icons/fa'
import { FiFacebook, FiInstagram, FiMail, FiTwitter, FiYoutube } from 'react-icons/fi'

export const Socials: React.FC<FlexBoxProps> = (props) => {
  return (
    <FlexRow flexWrap="wrap" {...props}>
      <ButtonEx color="inherit" href="https://instagram.com/elisetrouw/" target="_blank">
        <FiInstagram fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://youtube.com/elisetrouw/" target="_blank">
        <FiYoutube fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://facebook.com/elisetrouwmusic/" target="_blank">
        <FiFacebook fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://twitter.com/elisetrouw/" target="_blank">
        <FiTwitter fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://open.spotify.com/artist/6jeroC7T0j4Dvz9y3gtofR" target="_blank">
        <FaSpotify fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://music.apple.com/us/artist/elise-trouw/1119856607" target="_blank">
        <AiOutlineApple fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="mailto:management@elisetrouw.com" target="_blank">
        <FiMail fontSize={24} />
      </ButtonEx>
      <ButtonEx color="inherit" href="https://shop.elisetrouw.com" target="_blank">
        <AiOutlineShoppingCart fontSize={24} />
      </ButtonEx>
    </FlexRow>
  )
}
