import { useTheme } from '@mui/material'
import { AppBarEx, AppBarExProps } from '@xylabs/sdk-react'

import { SocialsToolbar } from './SocialsToolbar'
import { Title } from './Title'

export const CustomAppBar: React.FC<AppBarExProps> = () => {
  const theme = useTheme()
  return (
    <AppBarEx
      style={{ backgroundColor: theme.palette.background.default }}
      contextToolbar={<Title />}
      systemToolbar={<SocialsToolbar />}
      position="fixed"
    ></AppBarEx>
  )
}
