// eslint-disable-next-line import/no-internal-modules
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Menu, MenuItem, Toolbar, ToolbarProps } from '@mui/material'
import { ButtonEx } from '@xylabs/sdk-react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import logoWhite from './img/elise_logo_black.png'

export const Title: React.FC<ToolbarProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const navigate = useNavigate()
  return (
    <Toolbar {...props}>
      <IconButton
        onClick={(event) => {
          setMenuOpen(!menuOpen)
          setAnchorEl(event.currentTarget)
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <ButtonEx href="/">
        <img alt="Elise Trouw" src={logoWhite} height={64} />
      </ButtonEx>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => setMenuOpen(false)} onClick={() => setMenuOpen(!menuOpen)}>
        <MenuItem component="a" href="https://www.youtube.com/channel/UCgslJ2VwZHVxw-D095WQ3lQ" target="_blank">
          Videos
        </MenuItem>
        <MenuItem component="a" onClick={() => navigate('/tour/')}>
          Tour
        </MenuItem>
        <MenuItem component="a" onClick={() => navigate('/remix/')}>
          Remix
        </MenuItem>
        <MenuItem component="a" onClick={() => navigate('/contact/')}>
          Contact
        </MenuItem>
        <MenuItem component="a" href="https://shop.elisetrouw.com/" target="_blank">
          Shop
        </MenuItem>
      </Menu>
    </Toolbar>
  )
}
