import { Box } from '@mui/material'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Contact, Error404, Home, Remix, Tour } from '../Pages'
import { SubscribeForm } from '../SubscribeForm'
import { SubscribeFormThankYou } from '../SubscribeFormThankYou'
import { themeOptions } from '../theme'
import { checkDomain } from './checkDomain'

checkDomain()

export const App: React.FC = () => {
  return (
    <InvertibleThemeProvider options={themeOptions}>
      <BrowserRouter>
        <Box className="App">
          <Box>
            <Routes>
              <Route path="/subscribe" element={<SubscribeForm />} />
              <Route path="/thankyou" element={<SubscribeFormThankYou />} />
              <Route path="/tour" element={<Tour />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
              <Route element={<Remix />} path="/remix" />
              <Route element={<Error404 />} path="*" />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </InvertibleThemeProvider>
  )
}
