import { IconButton, Menu } from '@mui/material'
import { FlexBoxProps, FlexRow } from '@xylabs/sdk-react'
import { useState } from 'react'
import { MdShare } from 'react-icons/md'

import { Socials } from './Socials'

export const SocialsMenu: React.FC<FlexBoxProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  return (
    <FlexRow {...props}>
      <IconButton
        onClick={(event) => {
          setMenuOpen(!menuOpen)
          setAnchorEl(event.currentTarget)
        }}
      >
        <MdShare fontSize="large" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => setMenuOpen(false)} onClick={() => setMenuOpen(!menuOpen)}>
        <Socials flexDirection="column" />
      </Menu>
    </FlexRow>
  )
}
