import { Card, CardContent, Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonEx } from '@xylabs/sdk-react'
import React from 'react'

const useStyles = makeStyles({
  card: {
    margin: 32,
  },
})

export const SubscribeFormThankYou: React.FC = () => {
  const classes = useStyles()
  return (
    <Container>
      <Grid container direction="column">
        <Card className={classes.card}>
          <CardContent>
            <h2 data-drip-attribute="headline">Elise Trouw Mailing List Registration Complete</h2>
            <p>
              Thank you for registering for the Elise Trouw mailing list. Please check your email for a confirmation email to confirm your
              registration.
            </p>
            <div>
              <ButtonEx color="primary" variant="contained" to="/">
                Return Home
              </ButtonEx>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}
