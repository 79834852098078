import { Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  card: {
    margin: 32,
  },
})

export const SubscribeForm: React.FC = () => {
  const classes = useStyles()
  return (
    <Container>
      <Grid container direction="column">
        <Card className={classes.card}>
          <CardContent>
            <form action="https://www.getdrip.com/forms/269168904/submissions" data-drip-embedded-form="269168904" method="post" noValidate>
              <h2 data-drip-attribute="headline">Elise Trouw Mailing List</h2>
              <p>Sign up for the Elise Trouw mailing list and be the first to know about new music and tours.</p>
              <div data-drip-attribute="description"></div>
              <div>
                <TextField fullWidth={true} id="drip-email" label="Email Address" name="fields[email]" type="email" />
              </div>
              <div>
                <TextField id="drip-eu-consent-denied" name="fields[eu_consent]" type="hidden" value="denied" />
                <FormControlLabel
                  control={<Checkbox id="drip-eu-consent" name="fields[eu_consent]" value="granted" />}
                  label="By ticking this box, you confirm you're cool with us emailing you."
                />
              </div>
              <div>
                <input
                  name="fields[eu_consent_message]"
                  type="hidden"
                  value="By ticking this box, you confirm you&#39;re cool with us emailing you."
                />
              </div>
              <div aria-hidden="true" style={{ display: 'none' }}>
                <label>
                  {' '}
                  Website
                  <input id="website" name="website" type="text" {...{ autoComplete: 'false', tabIndex: -1 }} value="" />
                </label>
              </div>
              <div>
                <Button color="primary" data-drip-attribute="sign-up-button" type="submit" value="Sign Up" variant="contained">
                  Register
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}
